import * as React from "react";
import data from "./../../data/list-colors.json";

const colorGridStyle = {
  display: "flex",
  flexWrap: "wrap",
};

const buttonWrapper = {
  display: "flex",
  margin: "20px 0",
  justifyContent: "space-between",
  alignItems: "center",
};

const h3Style = {
  marginBottom: "0",
};

export default function ColorList({ selectedColor, onReset }) {
  const colorsToShow = getColorsToShow(selectedColor, data);

  const colorNode = colorsToShow.map((color) => {
    return (
      <ColorNode
        key={`${color.hex}`}
        displayedName={color.displayedName}
        hex={color.hex}
      />
    );
  });

  const closeToNode =
    selectedColor === null || selectedColor === undefined ? (
      <div style={buttonWrapper}>
        <h3 style={h3Style}>All Colors</h3>
      </div>
    ) : (
      <div style={buttonWrapper}>
        <h3 style={h3Style}>Close to</h3>
        <button className="custom-buttom" onClick={onReset}>
          See All
        </button>
      </div>
    );

  return (
    <div>
      {closeToNode}
      <div style={colorGridStyle}>{colorNode}</div>
    </div>
  );
}

const ColorNode = React.memo(function ColorNode({ hex, displayedName }) {
  return (
    <div key={`${hex}`} className="color-block">
      <div className="color-name">{displayedName}</div>
      <div className="color-box" style={{ background: `#${hex}` }} />
    </div>
  );
});

function getColorsToShow(selectedColor, allColors) {
  if (selectedColor === null || selectedColor === undefined) {
    return allColors;
  }
  const { r, g, b } = getRGBfromHexa(selectedColor);
  const { h, s, l } = getHSLFromHex(selectedColor);

  let ndf1 = 0;
  let ndf2 = 0;
  let ndf = 0;
  let cl = -1;
  let df = -1;

  for (let i = 0; i < allColors.length; i++) {
    const currentColor = allColors[i];

    if (selectedColor === `#${currentColor.hex}`) {
      return [currentColor];
    }

    const { red: ri, green: gi, blue: bi } = currentColor;
    const { hue: hi, saturation: si, lightness: li } = currentColor;

    ndf1 = Math.pow(r - ri, 2) + Math.pow(g - gi, 2) + Math.pow(b - bi, 2);
    ndf2 = Math.pow(h - hi, 2) + Math.pow(s - si, 2) + Math.pow(l - li, 2);
    ndf = ndf1 + ndf2 * 2;
    if (df < 0 || df > ndf) {
      df = ndf;
      cl = i;
    }
  }

  return cl < 0 ? [] : [allColors[cl]];
}

function getRGBfromHexa(hexaColor) {
  return {
    r: parseInt("0x" + hexaColor.substring(1, 3)),
    g: parseInt("0x" + hexaColor.substring(3, 5)),
    b: parseInt("0x" + hexaColor.substring(5, 7)),
  };
}

function getHSLFromHex(hexaColor) {
  const { r, g, b } = {
    r: parseInt("0x" + hexaColor.substring(1, 3)) / 255,
    g: parseInt("0x" + hexaColor.substring(3, 5)) / 255,
    b: parseInt("0x" + hexaColor.substring(5, 7)) / 255,
  };
  let min, max, delta, h, s, l;

  min = Math.min(r, Math.min(g, b));
  max = Math.max(r, Math.max(g, b));
  delta = max - min;
  l = (min + max) / 2;

  s = 0;
  if (l > 0 && l < 1) s = delta / (l < 0.5 ? 2 * l : 2 - 2 * l);

  h = 0;
  if (delta > 0) {
    if (max === r && max !== g) h += (g - b) / delta;
    if (max === g && max !== b) h += 2 + (b - r) / delta;
    if (max === b && max !== r) h += 4 + (r - g) / delta;
    h /= 6;
  }
  return { h: parseInt(h * 255), s: parseInt(s * 255), l: parseInt(l * 255) };
}
